import { captureException, withScope } from '@sentry/react';
import { type DefaultOptions, MutationCache, QueryCache, QueryClient } from '@tanstack/react-query';

function logErrorToSentry(error: unknown) {
  return captureException(error);
}

export const queryClientOptions = {
  queries: {
    // this enables better structural sharing if the same request is fired at different times while rendering
    staleTime: 5000,
    // tbd -> refetchOnWindowFocus: process.env.NODE_ENV !== 'development',
  },
  mutations: { onError: logErrorToSentry },
} satisfies DefaultOptions;

// TODO: importing it directly might get some unwanted side-effects
// initialize this QueryClient where the QueryClientProvider is deployed
/** @deprecated please access the queryClient via useQueryClient */
export const queryClient = new QueryClient({
  // better logging in sentry by using the cache, so we can access the mutation and query
  // https://aronschueler.de/blog/2022/12/16/generating-meaningful-issues-in-sentry-with-react-query-+-axios/
  mutationCache: new MutationCache({
    onError: (err, _variables, _context, mutation) => {
      withScope((scope) => {
        scope.setContext('mutation', {
          mutationId: mutation.mutationId,
          variables: mutation.state.variables,
        });
        if (mutation.options.mutationKey) {
          scope.setFingerprint(
            // Duplicate to prevent modification
            [...mutation.options.mutationKey] as string[],
          );
        }
        logErrorToSentry(err);
      });
    },
  }),
  queryCache: new QueryCache({
    onError: (err, query) => {
      withScope((scope) => {
        scope.setContext('query', { queryHash: query.queryHash });
        // strip away all numbers, so that we don’t get one issue per query parameter combination
        scope.setFingerprint([query.queryHash.replaceAll(/\d/g, '0')]);
        logErrorToSentry(err);
      });
    },
  }),
  defaultOptions: queryClientOptions,
});
